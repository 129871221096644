<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.794" x2="0.442" y2="1">
     <stop offset="0" stop-color="#69a6f8"/>
     <stop offset="1" stop-color="#002895"/>
    </linearGradient>
   </defs>
   <g id="Playstation" transform="translate(-370 -528)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(370 528)" width="60"/>
    <path d="M38.531,25.217a7.286,7.286,0,0,1-2.619,1.64L22.07,31.825V28.16l10.184-3.631c1.154-.412,1.336-1,.391-1.309a6.826,6.826,0,0,0-3.793.2l-6.783,2.4V22a23.927,23.927,0,0,1,5.109-1.134,20.128,20.128,0,0,1,8.787,1.046C38.949,22.862,39.287,24.259,38.531,25.217ZM23.386,18.974V9.593c0-1.1-.2-2.112-1.235-2.4-.79-.256-1.282.479-1.282,1.579V32.25l-6.331-2.011V2.25a76.267,76.267,0,0,1,8.72,2.389c5.366,1.843,7.181,4.137,7.181,9.3,0,5.028-3.1,6.938-7.053,5.035Zm-20.47,8.8c-3.064-.864-3.577-2.666-2.18-3.7A14.977,14.977,0,0,1,4.225,22.4L13.3,19.17v3.678L6.769,25.184c-1.154.412-1.33,1-.391,1.309a6.826,6.826,0,0,0,3.793-.2L13.3,25.157V28.45a20.535,20.535,0,0,1-10.387-.675Z" data-name="Icon awesome-playstation" id="Icon_awesome-playstation" style="fill: url(#linear-gradient);" transform="translate(380.002 539.75)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>